import React from 'react';

const IS_DEVELOPMENT = process.env.NODE_ENV === "development";

// custom hook, see https://reactjs.org/docs/hooks-custom.html
// used to log state changes for debugging on development builds
function useState(initial, name) {
    const [x, setX] = React.useState(initial);
    const logSetX = React.useCallback((newx) => {
        console.log("🚩 set", { [name]: JSON.stringify(newx) });
        setX(newx);
    }, [name]);

    return [x, name ? logSetX : setX];
}


export default IS_DEVELOPMENT ? useState : React.useState;

export const useDebugState = useState; // just for testing
