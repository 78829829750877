import React, { useState, useEffect, useRef, useCallback } from 'react';

export function Sticky({ minTop, minLeft, stuckClassName, onScrollIntoView, children }) {
    const outer = useRef(null);
    const inner = useRef(null);
    const [top, setTop] = useState(null);
    const [left, setLeft] = useState(null);
    const [height, setHeight] = useState(null);
    const [inview, setInview] = useState(true);

    const saveLayout = useCallback(() => {
        const rect = outer.current?.getBoundingClientRect();
        setTop(rect?.top);
        setLeft(rect?.left);
        setHeight(inner.current?.offsetHeight);
        if (rect.bottom <= window.innerHeight && rect.right <= window.innerWidth) {
            if (!inview) {
                setInview(true);
                onScrollIntoView?.();
            }
        } else {
            setInview(false);
        }
    }, [onScrollIntoView, inview]);

    useEffect(() => {
        ['scroll', 'resize'].forEach(e => window.addEventListener(e, saveLayout));
        return () => ['scroll', 'resize'].forEach(e => window.removeEventListener(e, saveLayout));
    }, [saveLayout]);

    useEffect(() => { // eslint-disable-line react-hooks/exhaustive-deps
        // run this after every render, to catch resizings of inner element
        // a subsequent render only happens if the height or top changes
        saveLayout();
    });

    const props = {};

    if (top < minTop || left < minLeft) {
        props.style = {
            position: "fixed",
            top: top < minTop ? minTop : top,
            left: left < minLeft ? minLeft : left,
        };
        if (stuckClassName) {
            props.className = stuckClassName;
        }
    }

    return (
        <div style={{ height }} ref={outer}>
            <div ref={inner} {...props}>
                {children}
            </div>
        </div >
    );
}
