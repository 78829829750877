import React, { useCallback } from 'react';
import useState from '../hooks/usestate';
import useGene from '../hooks/usegene';
import { GENE_ATTRIBUTES } from '../constants'
import { Partners } from '../components/partners';
import './genome_variation.scss';

function GenomeVariation() {
    let [error, setError] = useState(null, 'error');

    const geneArg = window.location.pathname.split("/").pop();
    const [gene] = useGene(setError, geneArg);

    const renderField = useCallback(id => {
        const label = GENE_ATTRIBUTES[id];
        const value = gene[id] === "" ? '-' : gene[id];

        let content = null;
        if (("" + value).startsWith("http")) {
            content = <a href={value} onClick={e => e.stopPropagation()} target="_blank" rel="noreferrer">{value}</a>
        } else if (id === "name") {
            content = <em key={2}>{value}</em>;
        } else {
            content = value;
        }

        return (
            <div key={id} className="form-field mandatory cf">
                <label className="fr-label" htmlFor={id}>{label}</label>
                <div id={id} className="input-wrap with-top-padding smooth">
                    {content}
                </div>
            </div>
        )
    }, [gene]);


    return (
        <div className="genomeVariation">
            <div className="main-header">
                <h1><img src="/logo.png" alt="The HGV Database" /></h1>
            </div>

            {error && <div className="error">{error}</div>}

            <div className="fields">
                {gene && Object.keys(GENE_ATTRIBUTES).map(renderField)}
            </div>

            <div className="centered-block">
                <a className="back-arrow" href="/">Go to homepage</a>
            </div>

            <div className="main-footer">
                <Partners />
            </div>
        </div>
    );
}

export default GenomeVariation;
