import React from 'react';
import useState from '../hooks/usestate';
import { API, GOTO, MESSAGES } from '../constants'
import { Partners } from '../components/partners';
import { jsonFetch } from '../lib/fetch';
import './login.scss';


function Login() {
    let [error, setError] = useState(null, 'error');
    let [email, setEmail] = useState("", 'email');
    let [password, setPassword] = useState("", 'password');

    const login = event => {
        event.preventDefault();

        if (!email || !password) {
            setError(MESSAGES.missingCredentials);
            return;
        }

        async function fetchUser() {
            try {
                const json = await jsonFetch.post(API.login, { formData: { email, password }, csrf: false });
                if (json.success) {
                    GOTO.admin();
                } else if (json.error) {
                    setError(json.error)
                }
            } catch (e) {
                console.error("caught exception", e);
                setError("Cannot login: " + e.message);
            }
        }
        fetchUser();
    };

    return (
        <div className="login">
            <div className="space" />

            <h1><img src="/logo.png" alt="The HGV Database" /></h1>

            {error && <div className="error">{error}</div>}

            <form onSubmit={login}>

                <label htmlFor="email">Email address</label>

                <input type="text" name="email" autoComplete="username"
                    className="inputbackground"
                    aria-invalid={error || undefined} autoFocus
                    value={email} onChange={e => { setError(null); setEmail(e.target.value) }} />

                <label htmlFor="password">Password</label>

                <input type="password" name="password" autoComplete="current-password"
                    className="inputbackground"
                    aria-invalid={error || undefined}
                    value={password} onChange={e => { setError(null); setPassword(e.target.value) }} />

                <span />

                <button className="redbutton" type="submit">Login</button>
            </form>

            <div className="space" />

            <div className="main-footer">
                <Partners />
            </div>
        </div>
    );
}

export default Login;
