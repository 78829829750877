import React, { useCallback, useEffect } from 'react';
import { API, GOTO, GENE_SET_ATTRIBUTES, GENE_REQUIRED_ATTRIBUTES, GENE_POSITIVE_ATTRIBUTES, SPECIAL_FIELDS, MESSAGE_TIMEOUT, MESSAGES, ROUTES, GENE_ATTRIBUTES } from '../constants';
import { OptionSelector } from '../components/optionselector';
import { Partners } from '../components/partners';
import { makeTemporary, gotoTopOfPage } from '../lib/utils';
import { jsonFetch } from '../lib/fetch';
import useState from '../hooks/usestate';
import useUser from '../hooks/useuser';
import useGene from '../hooks/usegene';
import useOptions from '../hooks/useoptions';
import './edit.scss';


const temporary = makeTemporary(MESSAGE_TIMEOUT);


export default function EditGene() {
    const [error, setError] = useState(null, 'error');
    const [successMessage, setSuccessMessage] = useState(null, 'successMessage');

    const options = useOptions(setError);

    useUser(setError, GOTO.login); // goto login page if not logged in

    const geneArg = ROUTES.findEditId() || 0;
    const [gene, setGene] = useGene(setError, geneArg, { showPrivateGenes: true });
    const [dirty, setDirty] = useState({}, 'dirty');
    const [showErrors, setShowErrors] = useState(false, 'showErrors');

    const onBeforeUnload = useCallback(e => {
        if (Object.entries(dirty).length > 0) {
            e.preventDefault();
            e.returnValue = '';
            return;
        }
        delete e['returnValue'];
    }, [dirty]);

    useEffect(() => {
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => window.removeEventListener('beforeunload', onBeforeUnload);
    }, [onBeforeUnload]);

    function saveAnd(callback) {
        return async e => {
            e.preventDefault();
            e.stopPropagation();
            setShowErrors(true);
            try {
                const res = await save(dirty, gene);
                setDirty({});
                callback(res);
            } catch (e) {
                temporary(setError, e.message);
                gotoTopOfPage();
                return null;
            }
        };
    }

    function setSimilar(committedGene) {
        temporary(setSuccessMessage, MESSAGES.geneSaved(committedGene));
        prepareState(committedGene);
    }

    function setNew(committedGene) {
        temporary(setSuccessMessage, MESSAGES.geneSaved(committedGene));
        prepareState({});
    }

    function prepareState(committedGene) {
        setGene({});
        setShowErrors(false);
        gotoTopOfPage();
        window.history.pushState({}, "", ROUTES.create); // calls onBeforeUnload
        const filter = ([field, value]) => field !== "id" && field !== "public" && value;
        setDirty(Object.fromEntries(Object.entries(committedGene).filter(filter)));
    }

    function setTemporaryErrorAndScroll(error) {
        temporary(setError, error);
        gotoTopOfPage();
    }

    const setField = useCallback((fieldId, value) => {
        if (!gene[fieldId] && !value) {
            delete dirty[fieldId];
        } else {
            dirty[fieldId] = value;
        }
        setDirty({ ...dirty });
    }, [gene, dirty, setDirty]);

    return (
        <div className="editGenomeVariation">
            <a href="/admin" className="back-arrow">back</a>

            <div className="main-header">
                <h1><img src="/logo.png" alt="The HGV Database" /></h1>
                <h4>Edit item</h4>
            </div>

            {successMessage && <div className="success-message"> <span className="icon" /> {successMessage} </div>}
            {error && <div className="error">{error}</div>}

            {gene && <form onSubmit={saveAnd(GOTO.admin)}>
                <div className="fields">
                    {GENE_SET_ATTRIBUTES.map(([fieldId, fieldLabel]) =>
                        <FieldLabelAndControl key={fieldId}
                            fieldId={fieldId} fieldLabel={fieldLabel} dirty={dirty} setField={setField} gene={gene}
                            options={options} showErrors={showErrors} setError={setTemporaryErrorAndScroll} />)}
                </div>

                <div className="action make-public">
                    <input id="public" type="checkbox" name="public" checked={Boolean(dirty.public)} disabled={gene?.public}
                        onChange={e => setField('public', Number(!dirty.public))} />
                    <label htmlFor="public" className={gene?.public ? "disabled" : ""}> Make it public</label>
                </div>

                <div className="action">
                    <button type="submit" className="redbutton save">Save</button>
                </div>

                <div className="action">
                    <button className="link-like add-similar" onClick={saveAnd(setSimilar)}>+ Save &amp; add similar</button>
                </div>

                <div className="action">
                    <button className="link-like add-new" onClick={saveAnd(setNew)}>+ Save &amp; add new</button>
                </div>

                <div className="action">
                    <a href="/admin" className="link-like cancel">Cancel</a>
                </div>

            </form>}

            <div className="main-footer">
                <Partners />
            </div>
        </div >
    );
}

function FieldLabelAndControl({ fieldId, fieldLabel, dirty, setField, gene, options, showErrors, setError }) {
    const fieldValue = gene.id ? dirty[fieldId] ?? gene[fieldId] : dirty[fieldId];
    const invalid = showErrors && getMessageIfInvalid(fieldId, fieldValue);

    const fieldPack = { id: fieldId, label: fieldLabel, value: fieldValue, invalid, set: setField };
    const control = <FieldControl field={fieldPack} options={options} setError={setError} />;

    return (
        <div key={fieldId} className="form-field">
            <label htmlFor={fieldId}>
                {fieldLabel}
                {GENE_REQUIRED_ATTRIBUTES.has(fieldId) && <span className="required"> *</span>}
            </label>
            {control}
        </div>
    )
}

function FieldControl({ field, options, setError }) {
    if (SPECIAL_FIELDS.has(field.id)) {
        return <OptionSelector field={field} options={options} setError={setError} />;
    }

    const className = `inputbackground ${field.invalid ? "invalid" : ""}`;
    const value = field.value ?? "";
    const onChange = e => field.set(field.id, e.target.value);

    if (field.id !== 'note') {
        return <input type="text" id={field.id} className={className} value={value} onChange={onChange} />;
    } else {
        return <textarea id={field.id} className={className} value={value} onChange={onChange} />;
    }
}

function getMessageIfInvalid(fieldId, value) {
    if (GENE_REQUIRED_ATTRIBUTES.has(fieldId)) {
        if (!value) {
            return MESSAGES.mandatoryFields;
        }
    }
    if (GENE_POSITIVE_ATTRIBUTES.has(fieldId) && value) {
        if (isNaN(value) || value <= 0) {
            return MESSAGES.badInteger;
        }
    }

    return null;
}

function validateFields(dirty, gene) {
    for (const fieldId of GENE_SET_ATTRIBUTES.map(([fieldId]) => fieldId)) {
        const value = dirty[fieldId] ?? gene[fieldId];
        const message = getMessageIfInvalid(fieldId, value);
        if (message) {
            return message;
        }
    }
    return null;
}

async function save(dirty, gene) {
    const error = validateFields(dirty, gene);
    if (error) {
        throw new Error(error);
    }
    try {
        const fetchFn = gene.id ? jsonFetch.put : jsonFetch.post;
        const url = gene.id ? API.genomeVariation(gene.id) : API.genes;
        const json = await fetchFn(url, { formData: dirty });
        if (json.error) {
            if (json.field) {
                const quoted = x => `'${x}'`;
                const from = quoted(json.field);
                const to = quoted(GENE_ATTRIBUTES[json.field]);
                throw new Error(json.error.replace(from, to));
            } else {
                throw new Error(json.error);
            }
        } else if (json.success) {
            return json.genome_variation;
        }
    } catch (e) {
        console.error("caught exception", e);
        throw new Error("Cannot save genome variation: " + e.message);
    }
}
