import React from 'react';
import PropTypes from 'prop-types';
import { GENE_ATTRIBUTES } from '../constants'
import { Select, Option } from './select';
import { SearchInput } from './search';
import style from './filters.css';


export function FilterRow({ children }) {
    return (
        <div className={style.filters}>
            {children}
        </div >
    )
}

export function PredefinedFilters({ options, filters, setFilter }) {
    const renderFilter = ([filter, values]) => {
        const selectedId = filters[filter] ?? 0;
        const label = values.find(v => selectedId === v.id)?.label;
        const onChange = id => setFilter(filter, id);
        return <Filter key={filter} label={label} values={values} onChange={onChange} />
    }

    return <> {Object.entries(options).map(renderFilter)} </>;
}
PredefinedFilters.propTypes = {
    options: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};


function Filter({ label, onChange, values }) {
    return (
        <Select placeholder={label} onChange={onChange}>
            {values.map(item => <Option key={item.id} value={item.id} title={item.label}>{item.label}</Option>)}
        </Select >
    )
}
Filter.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
};


export function TextSearch({ value, setValue, onSubmit }) {
    return (
        <div className={style.textSearch}>
            <SearchInput placeholder="search the database..."
                value={value}
                onChange={setValue}
                onSubmit={onSubmit}
            />
        </div>
    );
}
TextSearch.propTypes = {
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export function ColumnSelector({ displayColumns, setDisplayColumns }) {
    return (
        <div className={style.settingsFilter}>
            <Select multiple={true} value={displayColumns} placeholder="&nbsp;" onChange={setDisplayColumns}>
                {Object.keys(GENE_ATTRIBUTES).map(id =>
                    <Option key={id} value={id} title={GENE_ATTRIBUTES[id]}>{GENE_ATTRIBUTES[id]}</Option>)}
            </Select >
        </div >
    );
}
ColumnSelector.propTypes = {
    displayColumns: PropTypes.array.isRequired,
    setDisplayColumns: PropTypes.func.isRequired,
};
