// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PPKvR{position:relative}.OiFh4{background-color:initial;border:none;border-radius:3px;cursor:pointer;padding:8px}.OiFh4.visible{background-color:var(--gray);color:var(--white)}.d_FQy{border-radius:3px;min-width:100%;padding:8px 0;position:absolute;right:0;text-align:left;top:20px;top:25px;z-index:999}.d_FQy,.d_FQy button{background-color:var(--gray);color:var(--white)}.d_FQy button{border:none;cursor:pointer;margin:2px;white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/components/dropdown.css"],"names":[],"mappings":"AAAA,OACI,iBACJ,CAEA,OAGI,wBAA6B,CAF7B,WAAY,CAKZ,iBAAkB,CAFlB,cAAe,CACf,WAOJ,CAJI,eACI,4BAA6B,CAC7B,kBACJ,CAGJ,OAYI,iBAAkB,CADlB,cAAe,CAFf,aAAc,CAJd,iBAAkB,CAElB,OAAQ,CAJR,eAAgB,CAGhB,QAAS,CAIT,QAAS,CAGT,WAWJ,CARI,qBAfA,4BAA6B,CAC7B,kBAqBA,CAPA,cAGI,WAAY,CAGZ,cAAe,CAFf,UAAW,CACX,kBAEJ","sourcesContent":[".dropdown {\n    position: relative;\n}\n\n.toggle {\n    border: none;\n    padding: 8px;\n    background-color: transparent;\n    cursor: pointer;\n    padding: 8px;\n    border-radius: 3px;\n\n    &:global(.visible) {\n        background-color: var(--gray);\n        color: var(--white);\n    }\n}\n\n.menu {\n    background-color: var(--gray);\n    color: var(--white);\n    text-align: left;\n\n    position: absolute;\n    top: 20px;\n    right: 0;\n\n    padding: 8px 0;\n    top: 25px;\n    min-width: 100%;\n    border-radius: 3px;\n    z-index: 999;\n\n\n    & button {\n        background-color: var(--gray);\n        color: var(--white);\n        border: none;\n        margin: 2px;\n        white-space: nowrap;\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "PPKvR",
	"toggle": "OiFh4",
	"menu": "d_FQy"
};
export default ___CSS_LOADER_EXPORT___;
