import { useEffect, useState } from 'react';
import { MOBILE_WIDTH_THRESHOLD_PX } from '../constants';


// custom hook, see https://reactjs.org/docs/hooks-custom.html
export default function useMobile() {
    function testMobile() {
        return (window.innerWidth || 0) <= MOBILE_WIDTH_THRESHOLD_PX;
    }

    const [mobile, setMobile] = useState(testMobile());

    useEffect(() => {
        function handleResize() {
            setMobile(testMobile());
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [setMobile]);

    return mobile;
}
