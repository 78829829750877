import React, { useState } from 'react';
import { Select, Option } from '../components/select';
import { MESSAGE_TIMEOUT } from '../constants';
import { makeTemporary } from '../lib/utils';
import style from './optionselector.css';


const defaultPlaceholder = field => "Select " + field.label.toLowerCase();
const DEFAULT_MESSAGE = "This will appear on the public page and will be included in the search options!";
const temporary = makeTemporary(MESSAGE_TIMEOUT);


export function OptionSelector({ field, options, setError }) {
    const [newOption, setNewOption] = useState(null);
    const [message, setMessage] = useState(null);

    const validOptions = options.data[field.id]?.filter(o => o.id > 0) || [];

    const isDuplicate = newOption => validOptions.find(o => o.name === newOption);

    const onChange = optionId => {
        const value = validOptions.find(o => o.id === optionId);
        field.set(field.id, value?.label ?? "");
    }

    const onToggle = _show => setNewOption(null);
    const onSetNewOption = value => setNewOption(value);

    async function onCommitOption(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!newOption) {
            return;
        }

        if (isDuplicate(newOption)) {
            setError("An option with this name already exists.");
            return;
        }

        try {
            await options.saveNew(field.id, newOption);
            field.set(field.id, newOption);
            setNewOption(null);
            temporary(setMessage, `${newOption} saved`);
        } catch (e) {
            temporary(setMessage, e.message);
        }
    }

    async function onDeleteOption(e, option) {
        e.preventDefault();
        e.stopPropagation();

        try {
            await options.delete(option.id);
            temporary(setMessage, `${option.label} successfully deleted`);
        } catch (e) {
            temporary(setMessage, e.message);
        }
    }

    const placeholder = field.value ?? defaultPlaceholder(field);

    return <>
        {message ?
            <EditOptionBanner message={message} /> :
            (newOption?.length >= 0 && <EditOptionBanner message={DEFAULT_MESSAGE} />)}

        <div id={field.id} className={`${style.selector} ${field.invalid ? style.invalid : ""}`}>
            <Select placeholder={placeholder} onChange={onChange} white={true} onToggle={onToggle}>
                {validOptions.map(option =>
                    <ExistingOption key={option.id} option={option}
                        isSelected={option.label === field.value}
                        unused={options.unused} onDeleteOption={onDeleteOption} />)}
                <NewOption newOption={newOption} setNewOption={onSetNewOption} commitNewOption={onCommitOption} />
            </Select>
        </div>
    </>;
}

function ExistingOption({ option, isSelected, unused, onDeleteOption }) {
    return (
        <Option key={option.id} value={option.id} title={option.label}>
            <div className={style.optionLabel}>
                <div className={style.label}>
                    {isSelected ? <strong>{option.label}</strong> : option.label}
                </div>
                {!isSelected && unused.has(option.id) &&
                    <div className={style.delete} onClick={e => onDeleteOption(e, option)} />
                }
            </div>
        </Option>
    );
}

function NewOption({ newOption, setNewOption, commitNewOption }) {
    const item = newOption == null ?
        <label className={style.add} onClick={() => setNewOption("")}
            title="This will appear on the public page and will be included in the search options!"
        >+ Add item</label> :
        <div>
            <input className="inputbackground"
                autoFocus={true}
                placeholder="enter new item & press Enter"
                value={newOption}
                onChange={e => setNewOption(e.target.value)}
                onKeyDown={e => { if (e.key === "Enter") commitNewOption(e) }}
            />
            <button className={style.commit} onClick={commitNewOption} />
        </div>;
    return <div className={style.newOption}>{item}</div>;
}

function EditOptionBanner({ message }) {
    return (
        <div id="editOptionBanner" className={style.editOptionBanner}>
            <div>{message}</div>
        </div>
    );
}
