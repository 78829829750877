import React, { useEffect } from 'react';
import { API, GOTO } from '../constants';
import { jsonFetch } from '../lib/fetch';
import useState from '../hooks/usestate';
import useUser from '../hooks/useuser';
import './admin.scss';

function Kibana() {
    const [error, setError] = useState(null, "error");
    const [dashboard, setDashoard] = useState(null, "dashboard");
    useUser(setError, GOTO.login); // goto login page if not logged in

    useEffect(() => {
        jsonFetch.get(API.kibana)
            .then(json => setDashoard(json.dashboard))
            .catch(setError);
    }, []);

    return (
        <>
            <h1><img src="/logo.png" alt="The HGV Database" /></h1>

            <div className="admin">
                <div className="user">
                    <div>
                        <span>Admin </span>
                        <span>(</span>
                        <button className="link-like logout" onClick={() => logoutUser(setError)}>Logout</button>
                        <span>)</span>
                    </div>
                </div>

                <div className={"message" + (error ? " error" : "")}> {error} </div>

                <div>
                    <iframe title="Kibana" className="kibana-iframe" src={dashboard} />
                </div>
            </div>
        </>
    );
}

function logoutUser(setError) {
    jsonFetch.post(API.logout)
        .then(json => {
            if (json.success) { GOTO.root() }
        })
        .catch(setError);
}

export default Kibana;
