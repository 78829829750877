const HEADER_NAME = "X-CSRF-Token";
let csrfToken = null;

export function readCSRFToken(response) {
    const token = response?.headers?.get(HEADER_NAME);
    if (token) {
        csrfToken = token;
    }
}

export function csrfHeader() {
    return { [HEADER_NAME]: csrfToken }
}
