import { useEffect } from 'react';
import useState from './usestate';
import { API } from '../constants';
import { jsonFetch } from '../lib/fetch';


// custom hook, see https://reactjs.org/docs/hooks-custom.html
export default function useOptions(setError) {
    const [options, setOptions] = useState(placeholders, "options");
    const [unused, setUnused] = useState(new Set(), "unused options");

    useEffect(() => {
        async function fetchOptions() {
            try {
                const json = await jsonFetch.get(API.options);
                if (json.options) {
                    setOptions(json.options);
                    if (json.unused) {
                        setUnused(new Set(json.unused));
                    }
                } else if (json.error) {
                    setError(json.error)
                }
            } catch (e) {
                console.error("caught exception", e);
                setError("Cannot retrieve options: " + e.message);
            }
        }
        fetchOptions();
    }, [setError]);

    async function saveNewOption(masterName, name) {
        try {
            const formData = { master_name: masterName, name };
            const json = await jsonFetch.post(API.options, { formData });
            if (json.error) {
                throw new Error(json.error);
            } else if (json.option) {
                const newOptions = Object.assign({}, options);
                newOptions[masterName] = [...newOptions[masterName], json.option];
                setOptions(newOptions);
                setUnused(new Set([...unused, json.option.id]));
            }
        } catch (e) {
            console.error("caught exception", e);
            throw new Error("Cannot save option: " + e.message);
        }
    }

    async function deleteOption(optionId) {
        try {
            const json = await jsonFetch.delete(API.optionId(optionId));
            if (json.error) {
                throw new Error(json.error);
            } else if (json.success) {
                const newOptions = Object.assign({}, options);
                for (const masterName in newOptions) {
                    newOptions[masterName] = newOptions[masterName].filter(option => option.id !== optionId);
                }
                setOptions(newOptions);
                const newUnused = new Set(unused);
                newUnused.delete(optionId);
                setUnused(newUnused);
            }
        } catch (e) {
            console.error("caught exception", e);
            throw new Error("Cannot delete option: " + e.message);
        }
    }

    return { data: options, unused, saveNew: saveNewOption, delete: deleteOption };
}

// initial dummy values until the options are fetched, to minimize screen flicker
const placeholders = {
    "a": [{ "id": 0, "label": "All regions" }],
    "b": [{ "id": 0, "label": "All mutation types" }],
    "c": [{ "id": 0, "label": "All zygosity types" }]
}
