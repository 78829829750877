import { readCSRFToken, csrfHeader } from './csrftoken';
import { toFormData } from './utils';

export const jsonFetch = {
    get: (url) => myFetch(url),
    post: (url, { formData, csrf } = {}) => myFetch(url, 'POST', formData, csrf),
    put: (url, { formData, csrf } = {}) => myFetch(url, 'PUT', formData, csrf),
    delete: (url) => myFetch(url, 'DELETE'),
};


async function myFetch(url, method, formData, csrf) {
    const params = {};
    if (method) {
        params.method = method;
    }
    if (formData) {
        if (formData instanceof FormData) {
            params.body = formData;
        } else {
            params.body = toFormData(formData);
        }
    }
    if (csrf === undefined || csrf === null) {
        csrf = (method === "POST" || method === "PUT" || method === "DELETE");
    }
    if (csrf) {
        params.headers = csrfHeader();
    }

    let result;
    try {
        result = await fetch(url, params);
        readCSRFToken(result);
        return await result.json();
    } catch (e) {
        if (e instanceof SyntaxError) {
            throw new Error(result?.statusText);
        } else {
            throw e;
        }
    }
}
