import React, { useRef } from 'react';
import { GENE_ATTRIBUTES } from '../constants'
import { FilterRow, PredefinedFilters, ColumnSelector, TextSearch } from '../components/filters'
import { Partners } from '../components/partners';
import { AdvancedSearch } from '../components/advanced';
import { MainTable } from '../components/maintable';
import { Sticky } from '../lib/sticky';
import useOptions from '../hooks/useoptions';
import useQuery from '../hooks/usequery';
import useState from '../hooks/usestate';
import './home.scss';


function Home({ mobile }) {
    const [error, setError] = useState(null, "error");

    const options = useOptions(setError).data;

    const query = useQuery(setError);

    const [displayColumns, setDisplayColumns] = useState(Object.keys(GENE_ATTRIBUTES), "displayColumns");

    return (
        <>
            {mobile ?
                <Mobile options={options} query={query} /> :
                <Desktop options={options} query={query} displayColumns={displayColumns} setDisplayColumns={setDisplayColumns} />
            }

            <MainTable mobile={mobile} error={error} query={query} displayColumns={displayColumns} />
        </>
    );
}

function Mobile({ options, query }) {
    const mainTableRef = useRef(null);
    const scrollToTable = () => mainTableRef.current.scrollIntoView({ behavior: "smooth" });

    return (
        <>
            <div className="above-the-fold">
                <h1><img src="logo.png" alt="The HGV Database" /></h1>

                {query.params.advancedMode ?
                    <MobileAdvanced options={options} query={query} /> :
                    <MobileDefault options={options} query={query} scrollToTable={scrollToTable} />}

            </div>

            <div className="mh-background" />
            <div ref={mainTableRef} />
        </>
    );
}

function MobileAdvanced({ options, query }) {
    return (
        <>
            <PredefinedFilters options={options} filters={query.params.filters} setFilter={query.setFilter} />

            <AdvancedSearch
                mobile={true}
                open={query.params.advancedMode}
                advancedFilters={query.params.advancedFilters}
                setAdvancedFilters={query.setAdvancedFilters}
                clearAdvancedMode={() => query.toggleAdvancedMode({ clearAllFilters: true })}
                search={query.search} />
        </>
    );
}

function MobileDefault({ query, scrollToTable }) {
    const seeMoreText = query.isDefault() ? "See most recent" : "See results";
    return (
        <>
            <TextSearch value={query.params.searchTerm} setValue={query.setSearchTerm} onSubmit={query.search} />

            <button className="link-like" onClick={() => query.toggleAdvancedMode({ clearAllFilters: true })}> Advanced search </button>

            <div className="see-more">
                <button className="gray" onClick={scrollToTable}> {seeMoreText} </button>
            </div>
        </>
    );
}

function Desktop({ options, query, displayColumns, setDisplayColumns }) {
    return (
        <Sticky minLeft={0} stuckClassName="stuck" >
            <h1><img src="logo.png" alt="The HGV Database" /></h1>

            <Intro />

            <Sticky minTop={0} stuckClassName="stuck" >
                <FilterRow>
                    <h3>The HGV Database</h3>

                    <PredefinedFilters options={options} filters={query.params.filters} setFilter={query.setFilter} />

                    <ColumnSelector displayColumns={displayColumns} setDisplayColumns={setDisplayColumns} />

                    <TextSearch value={query.params.searchTerm} setValue={query.setSearchTerm} onSubmit={query.search} />

                    <button className="link-like" onClick={query.toggleAdvancedMode}> Advanced </button>
                </FilterRow>
            </Sticky>

            {query.params.advancedMode &&
                <AdvancedSearch
                    mobile={false}
                    open={query.params.advancedMode}
                    advancedFilters={query.params.advancedFilters}
                    setAdvancedFilters={query.setAdvancedFilters}
                    clearAdvancedMode={() => query.toggleAdvancedMode({ clearAdvancedFilters: true })}
                    search={query.search} />
            }
            <div className="mh-background" />

        </Sticky>
    );
}

function Intro() {
    return (
        <>
            <div className="partners">
                <Partners showText={true} />
            </div>

            <p className="intro">The HGV database is a fully searchable online database of genome variations
                <br /> published in peer-reviewed Data Reports in <a target="_blank" href="http://nature.com/hgv" rel="noreferrer"> Human Genome Variation</a> <em>(ISSN 2054-345X)</em>.
                <br /> You can search and filter by different variables, including specific disease,
                <br /> gene, population or region.
            </p>
        </>
    );
}

export default Home;
