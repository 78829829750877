export const API = {
    options: "/api/options",
    optionId: id => `/api/option/${id}`,
    genes: "/api/genes",
    genomeVariation: id => `/api/genome_variation/${id}`,
    login: "/api/login",
    logout: "/api/logout",
    kibana: "/api/kibana",
};

export const ROUTES = {
    root: "/",
    login: "/login",
    admin: "/admin",
    genomeVariationPrefix: "/genome_variation",
    genomeVariationId: id => `/genome_variation/${id}`,
    kibana: "/kibana",
    create: "/add",
    editPrefix: "/edit",
    editId: id => `/edit/${id}`,
    findEditId: () => {
        const a = window.location.pathname.split("/");
        return a.length > 2 ? a[a.length - 1] : undefined;
    },
};

export const GOTO = {
    root() { window.location.assign(ROUTES.root) },
    login() { window.location.assign(ROUTES.login) },
    admin() { window.location.assign(ROUTES.admin); },
    create() { window.location.assign(ROUTES.create) },
    edit(id) { window.location.assign(ROUTES.editId(id)) },
    view(id) { if (id) window.open(ROUTES.genomeVariationId(id), id); },
};

export const GENE_ATTRIBUTES = {
    name: "Gene name",
    omim: "Gene/Locus MIM number",
    mutation_type: "Mutation type",
    disease: "Disease / Phenotype",
    omim2: "Phenotype MIM number",
    gene_bank_number: "GenBank accession number",
    mutation: "Mutation (HGVS format)",
    protein: "Protein alteration",
    codon: "Codon / Base change",
    chromosome: "Chromosome",
    genome_position: "Genome position",
    de_novo: "de novo / inherited",
    zygosity: "Zygosity",
    region: "Region",
    ethnicity: "Ethnicity / Population",
    reference: "Reference",
    author_name: "Author",
    note: "Note",
};


export const GENE_SET_ATTRIBUTES = (function () {
    const attributes = Object.entries(GENE_ATTRIBUTES);

    const authorIndex = attributes.findIndex(([key]) => key === "author_name");
    attributes.splice(authorIndex, 1, ['author_first_name', "Author Firstname"], ['author_last_name', "Author Lastname"]);

    return attributes;
})();

export const GENE_REQUIRED_ATTRIBUTES = new Set(["name", "omim", "mutation_type", "disease", "gene_bank_number", "chromosome", "genome_position", "zygosity", "region", "ethnicity", "author_first_name", "author_last_name"]);

export const GENE_POSITIVE_ATTRIBUTES = new Set(["omim", "omim2"]);

export const SPECIAL_FIELDS = new Set(["region", "mutation_type", "zygosity"]);

export const MOBILE_WIDTH_THRESHOLD_PX = 500;

export const ADVANCED_SEARCH_COMBO_FIELDS = { min: 3, max: 12 };

export const DOI_BASE_URL = "http://doi.org/";

export const MESSAGE_TIMEOUT = 10 * 1000;

export const MESSAGES = {
    missingCredentials: "Email and password are required",
    mandatoryFields: "Please fill in all the mandatory fields.",
    badInteger: "Please enter a valid number in the marked field.",
    geneSaved: gene => 'Your item was successfully saved. The following DOI ' +
        (gene.public ?
            'is available for this genome variation: ' :
            'will be available for the genome variation when it will be published: ') +
        gene.doi,
};

export const AUTOLOAD_COUNT = 4;
