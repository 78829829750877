// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TiHsC{border:1px solid #ddd!important;border-radius:3px;height:26px;width:204px}.TiHsC input[data-size=S]{color:var(--gray);height:20px;width:100%}.TiHsC input[data-size=S]::-webkit-input-placeholder{color:var(--gray);opacity:.5}.TiHsC input[data-size=S]::placeholder{color:var(--gray);opacity:.5}.TiHsC button{background-color:var(--white);background:transparent;border:none;padding:0}.TiHsC svg{fill:var(--alternative_gray);height:28px;padding:0 4px}", "",{"version":3,"sources":["webpack://./src/components/search.css"],"names":[],"mappings":"AAAA,OAII,+BAAiC,CADjC,iBAAkB,CADlB,WAAY,CADZ,WA4BJ,CAvBI,0BACI,iBAAkB,CAClB,WAAY,CACZ,UAMJ,CAJI,qDACI,iBAAkB,CAClB,UACJ,CAHA,uCACI,iBAAkB,CAClB,UACJ,CAGJ,cACI,6BAA8B,CAG9B,sBAAuB,CAFvB,WAAY,CACZ,SAEJ,CAEA,WAEI,4BAA6B,CAD7B,WAAY,CAEZ,aACJ","sourcesContent":[".searchInput {\n    width: 204px;\n    height: 26px;\n    border-radius: 3px;\n    border: 1px solid #ddd !important;\n\n    & input[data-size=\"S\"] {\n        color: var(--gray);\n        height: 20px;\n        width: 100%;\n\n        &::placeholder {\n            color: var(--gray);\n            opacity: 0.5;\n        }\n    }\n\n    & button {\n        background-color: var(--white);\n        border: none;\n        padding: 0;\n        background: transparent;\n    }\n\n    & svg {\n        height: 28px;\n        fill: var(--alternative_gray);\n        padding: 0 4px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": "TiHsC"
};
export default ___CSS_LOADER_EXPORT___;
