// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MMchc{display:flex;flex-direction:column;gap:8px;margin:0 auto 12px;max-width:946px}@media (max-width:500px){.MMchc{align-items:center;margin:0 auto}}.Pn36B{flex-flow:row wrap;gap:4px 20px}.tDrXD,.Pn36B{align-items:center;display:flex;justify-content:center}.tDrXD{gap:16px}@media (max-width:500px){.tDrXD{width:301px}}.tDrXD>:first-child{flex:1}@media (min-width:501px){.tDrXD:after{content:\"\";flex:1}}", "",{"version":3,"sources":["webpack://./src/components/advanced.css"],"names":[],"mappings":"AAAA,OACI,YAAa,CACb,qBAAsB,CACtB,OAAQ,CAER,kBAAmB,CADnB,eAOJ,CAJI,yBAPJ,OASQ,kBAAmB,CADnB,aAGR,CADI,CAGJ,OAEI,kBAAmB,CAGnB,YACJ,CAEA,cALI,kBAAmB,CAFnB,YAAa,CAGb,sBA0BJ,CAtBA,OAII,QAkBJ,CAhBI,yBANJ,OAOQ,WAeR,CAdI,CAEA,oBACI,MACJ,CAGA,yBACI,aACI,UAAW,CACX,MACJ,CACJ","sourcesContent":[".advancedSearch {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    max-width: 946px;\n    margin: 0 auto 12px;\n\n    @media (max-width: 500px) {\n        margin: 0 auto;\n        align-items: center;\n    }\n}\n\n.comboRows {\n    display: flex;\n    flex-flow: row wrap;\n    align-items: center;\n    justify-content: center;\n    gap: 4px 20px;\n}\n\n.buttonRow {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 16px;\n\n    @media (max-width: 500px) {\n        width: 301px;\n    }\n\n    &>:first-child {\n        flex: 1;\n    }\n\n    /* insert flex element to center the middle on desktop */\n    @media (min-width: 501px) {\n        &:after {\n            content: \"\";\n            flex: 1;\n        }\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advancedSearch": "MMchc",
	"comboRows": "Pn36B",
	"buttonRow": "tDrXD"
};
export default ___CSS_LOADER_EXPORT___;
