import React, { useCallback } from 'react';
import { GENE_ATTRIBUTES, GOTO, AUTOLOAD_COUNT } from '../constants';
import { Sticky } from '../lib/sticky';
import { throttle } from '../lib/utils';
import style from './maintable.css';


export function MainTable({ mobile, error, query, displayColumns }) {
    if (!query.results) {
        if (error) {
            return <ErrorPane error={error} />;
        } else {
            // don't render anything until we have results
            return null;
        }
    }

    const columnSet = new Set(displayColumns);
    const columns = Object.entries(GENE_ATTRIBUTES).filter(([id]) => columnSet.has(id));

    return mobile ?
        <MobileTable mobile={mobile} columns={columns} results={query.results} error={error} clear={query.clear}
            index={query.index} moveIndex={query.moveIndex} /> :
        <DesktopTable mobile={mobile} columns={columns} results={query.results} error={error} clear={query.clear}
            onRowClick={item => GOTO.view(item.id)}
            nextPage={query.nextPage} sortKeyOrder={query.sortKeyOrder} sortBy={query.sortBy} />;
}

export function DesktopTable({ columns, results, error, onRowClick, clear, nextPage, sortKeyOrder, sortBy }) {
    const [autoLoadCount, setAutoLoadCount] = React.useState(AUTOLOAD_COUNT);

    const onAutoLoad = useCallback(() => {
        throttle(() => {
            if (autoLoadCount <= 0) {
                return;
            }
            setAutoLoadCount(autoLoadCount - 1);
            nextPage();
        }, 1000)()
    }, [autoLoadCount, nextPage]);

    return (
        <>
            <div className={style.table}>
                <Sticky minTop={52} stuckClassName="stuckheader">
                    <div className="table-header">
                        <div className="row">
                            <VisibleHeaderCells columns={columns} sortKeyOrder={sortKeyOrder} sortBy={sortBy} />
                        </div>
                    </div>
                </Sticky>

                {!results.count && <NoResults clear={clear} />}

                <div className="table-body">
                    {results.items.map(item =>
                        <Row key={item.id} columns={columns} item={item} onRowClick={onRowClick} />)}
                </div>
            </div>

            <Sticky minLeft={0} onScrollIntoView={onAutoLoad}>
                {Boolean(results.count) && <div className={style.footer + (error ? " error-state" : "")}>
                    <strong> showing {results.items.length} </strong>
                    (out of {results.count})
                    {error ? <>
                        <span className="load-error">{error}</span>
                        <button className="link-like" onClick={nextPage}>Try again</button>
                    </> : false
                    }
                    {results.items.length < results.count && !error ?
                        <button className="link-like" onClick={nextPage}>Show more</button>
                        : false
                    }
                </div>}
            </Sticky>
        </>
    )
}

function VisibleHeaderCells({ columns, sortKeyOrder, sortBy }) {
    function sortFn(fieldId) {
        return ascending => sortBy(fieldId, ascending);
    }
    const [sortKey, sortAscending] = sortKeyOrder;
    return columns.map(([fieldId, fieldName]) => {
        const sorted = sortKey === fieldId ? sortAscending : null;
        return <HeaderCell key={fieldId} label={fieldName} sorted={sorted} sort={sortFn(fieldId)} />;
    });
}

function Row({ columns, item, onRowClick }) {
    const className = "row" + (item.public ? "" : " private");
    return (
        <div className={className} onClick={() => onRowClick && onRowClick(item)}>
            <VisibleCells columns={columns} mobile={false} item={item} />
        </div>
    )
}

function VisibleCells({ columns, mobile, item }) {
    return columns.map(([fieldId, fieldName, renderer]) => {
        const label = mobile ? fieldName : null;
        if (item) {
            return <FieldCell key={fieldId} id={fieldId} label={label} item={item} renderer={renderer} />;
        } else {
            return false;
        }
    });
}

function HeaderCell({ label, sorted, sort }) {
    return (
        <div className="col">
            <div className="th-name">
                <span onClick={() => sort()}>{label}</span>
                {sort && <div className="th-sort" >
                    <SortArrow up={true} active={sorted === true} onClick={() => sort(true)} />
                    <SortArrow up={false} active={sorted === false} onClick={() => sort(false)} />
                </div>}
            </div>
        </div>
    )
}

function SortArrow({ up, active, onClick }) {
    const className = `link-like th-${up ? "ascend" : "descend"}${active ? " active" : ""}`;
    const alt = up ? "sort ascending" : "sort descending";
    return (
        <button className={className} onClick={onClick}>
            <span alt={alt} />
        </button>
    );
}


function MobileTable({ columns, results, error, index, clear, moveIndex }) {
    const item = results.items[index] ?? {};
    return (
        <>
            <MobileNav index={index} count={results.count}
                moveIndex={moveIndex} error={error} />
            {error && <ErrorPane error={error} />}
            {!results.count ?
                <NoResults clear={clear} /> :
                <div className={style.table}>
                    <div className="table-body">
                        <VisibleCells columns={columns} mobile={true} item={item} />
                    </div >
                </div>
            }
        </>
    )
}

function MobileNav({ index, count, moveIndex }) {
    return (
        <Sticky minTop={0}>
            <div className={style.nav} >
                <h3>HGV database</h3>
                <span className="showing">showing {index + 1} / {count}</span>

                <div className="nav-arrows">
                    <div className="nav-arrow left" onClick={() => moveIndex(-1)}> </div>
                    <div className="nav-arrow right" onClick={() => moveIndex(1)}> </div>
                </div>
            </div >
        </Sticky>
    );
}


function FieldCell({ id, label, item, renderer }) {
    const value = item[id];

    let child = null;
    if (renderer) {
        child = renderer(item);
    } else if (("" + value).startsWith("http")) {
        child = <a href={value} onClick={e => e.stopPropagation()} target="_blank" rel="noreferrer">{value}</a>;
    } else {
        child = <span className="content" >{(value ?? " ") || "-"}</span>;
    }

    return (
        <div className="col">
            {label ? <div className="field-name">{label}:</div> : null}
            {child}
        </div>
    )
}

function NoResults({ clear }) {
    return (
        <div className={style.noResults}>
            <span alt="No results" />
            <button className="link-like" onClick={clear}>
                Clear all filters
            </button>
        </div>
    );
}

export function ErrorPane({ error }) {
    return (
        <div className={style.loadError}>
            <span>{error}</span>
            <button className="link-like" onClick={() => window.location.reload()}>
                Refresh
            </button>
        </div>
    );
}
