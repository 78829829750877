import React, { useCallback } from 'react';
import useState from '../hooks/usestate';
import Select, { Option } from "@figshare/components/ui/select";
import SearchInput from "@figshare/ui/searchInput";
import { justTheValue } from '../lib/utils';
import style from "./combosearch.css";

/**
 * A combo of a dropdown select and a search input.
 * @param className - style class name
 * @param keyOptions - object with keys/values for the options in select
 * @param key - key of the current option in select
 * @param setKey - function to set the key of select option
 * @param value - value displayed in the search input
 * @param setValue - function to set the value in the search input
 */
export function ComboSearch({ className, keyOptions, key_, setKey, value, setValue, submit }) {
    const [open, setOpen] = useState(false);

    const renderOption = (o) => {
        return <Option key={o[0]} value={o[0]}> {o[1]} </Option>
    };

    const myChange = useCallback(e => setValue(justTheValue(e)), [setValue]);
    const mySubmit = useCallback(e => submit(justTheValue(e)), [submit]);

    return (
        <div className={`inputbackground ${style.combo} ${className || ""}`}>
            <Select
                className={`${style.select} ${open ? style.open : ''}`}
                triggerClassName={`${style.selectTrigger}  ${open ? style.open : ''}`}
                contentClassName={style.selectContent}
                placeholder={keyOptions[key_] || "select"}
                onChange={setKey}
                onToggle={setOpen}
                size="S"
            >
                <Option value={null}>{"–– Clear selection ––"}</Option>
                {Object.entries(keyOptions).map(renderOption)}
            </Select >
            <SearchInput
                className={style.searchInput}
                placeholder={""}
                value={value || ""}
                onChange={myChange}
                onSubmit={mySubmit}
                disabled={!key_}
            />
        </div >
    )
}
