import style from "./partners.css";


export function Partners({ showText }) {
    return (
        <div className={style.partners}>
            {showText && <span>managed by</span>}

            <a href="http://nature.com/hgv" target="_blank" className={style.natureLogo} rel="noreferrer">
                <span alt="nature.com/hgv" />
            </a>

            <div className={style.mid} />

            {showText && <span>powered by</span>}

            <a href="http://figshare.com" target="_blank" className={style.figshareLogo} rel="noreferrer">
                <span alt="figshare.com" />
            </a>
        </div>
    )
}
