import { useEffect } from 'react';
import { API } from '../constants';
import { jsonFetch } from '../lib/fetch';
import useState from './usestate';


// custom hook, see https://reactjs.org/docs/hooks-custom.html
export default function useGene(setError, geneId, { showPrivateGenes } = {}) {
    const [gene, setGene] = useState(null, 'gene');

    useEffect(() => {
        let id = null;
        if (typeof geneId === 'string' || geneId instanceof String) {
            id = parseInt(geneId);
        } else if (typeof geneId === 'number') {
            id = geneId;
        }

        if (id === 0) {
            setGene({}); // empty gene
            return;
        } else if (!id) {
            setError('Invalid gene id');
            return;
        }

        async function fetchGene(id) {
            try {
                const queryString = showPrivateGenes ? "?public=0" : "";
                const json = await jsonFetch.get(API.genomeVariation(id) + queryString);
                if (json.genome_variation) {
                    setGene(addAuthorName(json.genome_variation));
                } else if (json.error) {
                    setError(json.error)
                }
            } catch (e) {
                console.error("caught exception", e);
                setError("Cannot retrieve genome variation: " + e.message);
            }
        }

        fetchGene(id);
    }, [setError, geneId, showPrivateGenes]);

    return [gene, setGene];
}


export function addAuthorName(gene) {
    return {
        ...gene,
        author_name: `${gene.author_first_name} ${gene.author_last_name}`.trim(),
    }
}
