import React, { useState } from 'react';
import { default as FeCoreSelect, Option as FeCoreOption } from "@figshare/components/ui/select";
import style from "./select.css";


export function Select({ placeholder, multiple, value, onChange, white, children, onToggle }) {
    const [open, setOpen] = useState(false);
    const whiteClass = white ? style.white : "";
    return (
        <FeCoreSelect
            className={`${style.select} ${whiteClass} ${open ? style.open : ''}`}
            triggerClassName={`${style.selectTrigger} ${open ? style.open : ''}`}
            contentClassName={style.selectContent}
            placeholder={placeholder}
            multiple={multiple}
            value={value}
            onChange={onChange}
            onToggle={x => { setOpen(x); onToggle && onToggle(x) }}
            size="S"
            renderTrigger={multiple ? renderTrigger : null}
        >
            {children}
        </FeCoreSelect >
    )
}

export function Option({ value, title, children }) {
    return (
        <FeCoreOption value={value} title={title}>
            {children}
        </FeCoreOption>
    );
}


function renderTrigger() {
    return <div className="wheelbackground" alt="Settings" />;
}
