import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GENE_ATTRIBUTES, SPECIAL_FIELDS, ADVANCED_SEARCH_COMBO_FIELDS } from '../constants';
import { ComboSearch } from './combosearch';
import style from "./advanced.css";


export function AdvancedSearch({ mobile, open, advancedFilters, setAdvancedFilters, clearAdvancedMode, search }) {
    const minFilterCount = mobile ? 1 : ADVANCED_SEARCH_COMBO_FIELDS.min;
    const [terms, fields] = useMemo(
        () => {
            const _terms = [...advancedFilters];
            for (let i = 0; i < Math.max(_terms.length, minFilterCount); i++) {
                if (!_terms[i] || !_terms[i].length) {
                    _terms[i] = [];
                }
            }

            const _fields = Object.keys(GENE_ATTRIBUTES)
                .filter(k => !SPECIAL_FIELDS.has(k))
                .filter(k => _terms.every(t => t[0] !== k))
                .reduce((acc, k) => { return { ...acc, [k]: GENE_ATTRIBUTES[k] }; }, {});

            return [_terms, _fields];
        },
        [advancedFilters, minFilterCount]
    );

    if (!open) {
        return false;
    }

    const setKeyAndTerm = (i, k, v) => {
        const newFilters = [...advancedFilters];
        newFilters[i] = [k, k === null ? "" : v];
        setAdvancedFilters(newFilters);
    }

    const moreFields = () => {
        while (advancedFilters.length < minFilterCount) {
            advancedFilters.push([]);
        }
        if (advancedFilters.length < ADVANCED_SEARCH_COMBO_FIELDS.max) {
            setAdvancedFilters([...advancedFilters, []]);
        }
    };

    const searchFields = () => {
        search();
    }

    return (
        <div className={`${style.advancedSearch}`}>
            <div className={style.comboRows}>
                {terms.map((term, i) =>
                    <ComboSearch key={i}
                        className={style.combo}
                        keyOptions={term ? { ...fields, [term[0]]: GENE_ATTRIBUTES[term[0]] } : fields}
                        key_={term[0]}
                        setKey={k => setKeyAndTerm(i, k, term[1])}
                        value={term[1]}
                        setValue={v => setKeyAndTerm(i, term[0], v)}
                        submit={searchFields}
                    />
                )}
            </div>
            <div className={style.buttonRow}>
                <div>
                    <button className={`link-like`} onClick={moreFields}> + More fields </button>
                </div>
                <button className="link-like" onClick={clearAdvancedMode}>
                    {mobile ? "Cancel" : "Clear filters"}
                </button>
                <button className="gray" onClick={searchFields}>Search</button>
            </div>
        </div >
    );
}
AdvancedSearch.propTypes = {
    mobile: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    advancedFilters: PropTypes.array.isRequired,
    setAdvancedFilters: PropTypes.func.isRequired,
    clearAdvancedMode: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired
};
