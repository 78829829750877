import { useEffect, useCallback } from 'react';
import useState from './usestate';
import { addAuthorName } from './usegene';
import { API, SPECIAL_FIELDS, GENE_ATTRIBUTES } from '../constants'


// custom hook, see https://reactjs.org/docs/hooks-custom.html
export default function useQuery(setError, { showPrivateGenes } = { showPrivateGenes: false }) {
    const [queryString, setQueryString] = useState("");

    const [params, setParams] = useState(defaultParams(), "params");

    const [results, setResults] = useState(null, "results");

    const [index, setIndex] = useState(0, 'index');
    const [sortKeyOrder, setSortKeyOrder] = useState([], 'sortKeyAscending');

    const search = useCallback(() => {
        setQueryString(toQueryString(params));
        setIndex(0);
    }, [params]);

    const captureError = useCallback(err => {
        if (err instanceof TypeError) {
            setError(`${err.message}`);
        } else if (err instanceof Error) {
            setError(`${err.name}: ${err.message}`);
        } else if (err instanceof String || typeof err === "string") {
            setError(err);
        } else {
            setError("Cannot fetch data");
            console.error(err);
        }
    }, [setError]);

    useEffect(() => {
        const urlParams = fromQueryString(window.location.search);
        setParams(urlParams);
        setQueryString(toQueryString(urlParams));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps -- only run the effect when the page is loaded

    useEffect(() => {
        fetch(urlJoin(API.genes, queryString, showPrivateGenes ? "public=0" : ""))
            .then(result => result.ok ? result.json() : Promise.reject(result.statusText))
            .then(json => {
                if (!json || typeof json !== "object") {
                    return json;
                }
                return { ...json, items: json.items?.map(addAuthorName) };
            })
            .then(setResults)
            .then(() => setError(null))
            .catch(captureError)

        window.history.pushState({}, "", urlJoin(window.location.pathname, queryString));
    }, [queryString, setError, captureError, showPrivateGenes]);

    const nextPage = useCallback(() => {
        if (!results.last_id) {
            return;
        }
        fetch(urlJoin(API.genes, queryString, 'last_id=' + results.last_id, showPrivateGenes ? "public=0" : ""))
            .then(result => result.ok ? result.json() : Promise.reject(result.statusText))
            .then(data => setResults(sort({
                items: [...results.items, ...data.items],
                count: results.count, // initial count is larger than next batch count
                last_id: data.last_id,
            }, sortKeyOrder[0], sortKeyOrder[1])))
            .then(() => setError(null))
            .catch(captureError)
    }, [results, queryString, setError, captureError, showPrivateGenes, sortKeyOrder]);

    return {
        params,

        results,

        setFilter: (key, value) => {
            let filters;
            if (value === 0) {
                const entries = Object.entries(params.filters).filter(([k, _v]) => k !== key);
                filters = Object.fromEntries(entries);
            } else {
                filters = { ...params.filters, [key]: value };
            }
            const newParams = { ...params, filters }
            setParams(newParams);
            setQueryString(toQueryString(newParams));
            setIndex(0);
        },

        setSearchTerm: (value) => {
            setParams({ ...params, searchTerm: value });
        },

        clear: () => {
            setParams(defaultParams());
            setQueryString(toQueryString(defaultParams()));
            setIndex(0);
        },

        isDefault: () => {
            return queryString === "";
        },

        toggleAdvancedMode: ({ clearAllFilters, clearAdvancedFilters } = {}) => {
            const open = !params.advancedMode;
            const newParams = { ...params, advancedMode: open };
            if (clearAllFilters) {
                newParams.searchTerm = "";
                newParams.filters = {};
                newParams.advancedFilters = [];
            }
            if (clearAdvancedFilters) {
                newParams.advancedFilters = [];
            }
            setParams(newParams);
            if (!open) {
                setQueryString(toQueryString(newParams));
                setIndex(0);
            }
        },

        setAdvancedFilters: (keyTermArray) => {
            setParams({ ...params, advancedFilters: keyTermArray });
        },

        search,

        nextPage,

        index,

        moveIndex(delta) {
            const newIndex = index + delta;
            if (newIndex >= 0 && newIndex < results.count) {
                setIndex(newIndex);
            }
            if (newIndex >= results.items.length) {
                nextPage();
            }
        },

        sortKeyOrder,
        sortBy(fieldId, ascending) {
            if (fieldId == null) {
                setSortKeyOrder([]);
                return;
            }
            if (ascending == null) {
                if (sortKeyOrder[0] === fieldId) {
                    const old = sortKeyOrder[1];
                    ascending = old == null ? false : !old;
                } else {
                    ascending = false;
                }
            }
            setSortKeyOrder([fieldId, ascending]);
            setResults(sort(results, fieldId, ascending));
        },

        updateLocalResultItem(item) {
            if (!results?.items) {
                console.error("No items are present in result");
                return;
            }
            const i = results.items.findIndex(x => x.id === item.id);
            if (i >= 0) {
                results.items[i] = item;
                setResults({ ...results });
            }
        },

        deleteLocalResultItem(item) {
            if (!results?.items) {
                console.error("No items are present in result");
                return;
            }
            const i = results.items.findIndex(x => x.id === item.id);
            if (i >= 0) {
                results.items.splice(i, 1);
                results.count -= 1;
                setResults({ ...results });
            }
        }
    }
}

function defaultParams() {
    return {
        searchTerm: "",
        filters: {},
        advancedMode: false,
        advancedFilters: [],
    }
}

function toQueryString(params) {
    const flatParams = [];

    if (params.searchTerm) {
        flatParams.push(`search_term=${encodeURIComponent(params.searchTerm)}`);
    }

    for (const [key, value] of Object.entries(params.filters)) {
        flatParams.push(`${key}=${value}`);
    }

    if (params.advancedMode && params.advancedFilters?.length) {
        for (const term of params.advancedFilters) {
            if (term && term.length) {
                const [key, value] = term;
                if (key && value) {
                    flatParams.push(`${key}=${value}`);
                }
            }
        }
    }

    return flatParams.join("&");
}


function fromQueryString(queryString) {
    const urlParams = new URLSearchParams(queryString);
    const params = defaultParams();

    if (urlParams.has("search_term")) {
        params.searchTerm = urlParams.get("search_term");
    }
    for (const [field, value] of urlParams.entries()) {
        if (!field || field === "") {
            continue;
        }
        if (SPECIAL_FIELDS.has(field)) {
            const intvalue = parseInt(value);
            if (intvalue > 0) {
                params.filters[field] = intvalue;
            }
        } else if (GENE_ATTRIBUTES[field]) {
            params.advancedMode = true;
            params.advancedFilters.push([field, value || ""]);
        }
    }

    return params;
}

function urlJoin(path, ...fragments) {
    const query = fragments.filter(f => f).join("&");
    if (path && query) {
        return path + "?" + query;
    } else {
        return path + query;
    }
}

function sort(results, fieldId, ascending) {
    if (!fieldId || ascending == null) {
        return results;
    }
    const ascOrder = ascending ? -1 : 1;
    const isNumeric = fieldId === "omim" || fieldId === "omim2";
    results.items?.sort((item1, item2) => {
        const v1 = isNumeric ? (item1[fieldId] | 0) : item1[fieldId];
        const v2 = isNumeric ? (item2[fieldId] | 0) : item2[fieldId];
        if (v1 === v2) {
            return 0;
        }
        if (v1.localCompare) {
            return v1.localCompare(v2) * (ascending ? 1 : -1);
        } else {
            return v1 < v2 ? ascOrder : -ascOrder;
        }
    });
    return { ...results };
}
