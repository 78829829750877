// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../hgv.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BMjdX span{padding:0 10px}.kzsJX{display:inline-block;width:80px}@media (max-width:500px){.kzsJX{width:60px}}.fg3DP{background-position:0 -202px;height:24px;width:101px}.YpuCM,.fg3DP{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");display:inline-block;vertical-align:middle}.YpuCM{background-position:-1px 0;height:32px;width:104px}", "",{"version":3,"sources":["webpack://./src/components/partners.css"],"names":[],"mappings":"AAAA,YACI,cACJ,CAEA,OACI,oBAAqB,CACrB,UAKJ,CAHI,yBAJJ,OAKQ,UAER,CADI,CAGJ,OAII,4BAA6B,CAE7B,WAAY,CADZ,WAEJ,CAEA,cANI,wDAAmC,CAFnC,oBAAqB,CACrB,qBAcJ,CAPA,OAII,0BAA2B,CAE3B,WAAY,CADZ,WAEJ","sourcesContent":[".partners span {\n    padding: 0 10px\n}\n\n.mid {\n    display: inline-block;\n    width: 80px;\n\n    @media (max-width: 500px) {\n        width: 60px;\n    }\n}\n\n.natureLogo {\n    display: inline-block;\n    vertical-align: middle;\n    background-image: url('../hgv.png');\n    background-position: 0 -202px;\n    width: 101px;\n    height: 24px;\n}\n\n.figshareLogo {\n    display: inline-block;\n    vertical-align: middle;\n    background-image: url('../hgv.png');\n    background-position: -1px 0;\n    width: 104px;\n    height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partners": "BMjdX",
	"mid": "kzsJX",
	"natureLogo": "fg3DP",
	"figshareLogo": "YpuCM"
};
export default ___CSS_LOADER_EXPORT___;
