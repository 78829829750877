import api from "!../../node_modules/@figshare/tools/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].rules[2]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].rules[3]!../../node_modules/source-map-loader/dist/cjs.js!./search.css";

var options = {};

options.insert = function insertTag(element) {
    const criticalStyles = document.querySelector("head style[id=\"criticalCSS\"]");
    if (criticalStyles) {
        criticalStyles.parentNode.insertBefore(element, criticalStyles);
    } else {
        document.querySelector("head").appendChild(element);
    }

    return true;
};
options.singleton = false;

var update = api(content, options);



export default content.locals || {};