import React from 'react';
import FeCoreDropdown, { Menu, Toggle } from '@figshare/components/base/dropdown';

import style from './dropdown.css';

export function Dropdown({ isVisible, onToggle, children }) {
    return <FeCoreDropdown isVisible={isVisible} onToggle={onToggle}>
        {() => <div className={style.dropdown}>
            <Toggle>
                {props => <button className={style.toggle + (props.isVisible ? " visible" : "")} onClick={props.onToggle}>
                    <div className="wheelbackground" />
                </button>}
            </Toggle>

            <Menu>
                {props => {
                    return props.isVisible && (
                        <div className={style.menu} role="menu"> {children} </div>
                    )
                }}
            </Menu>
        </div>}
    </FeCoreDropdown >
}
