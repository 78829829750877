export function makeTemporary(timeoutMs) {
    let timeout = null;
    return function temporary(setFn, arg1) {
        setFn(arg1);
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => setFn(null), timeoutMs)
    }
}

export function gotoTopOfPage() {
    window.scrollTo(0, 0);
}

export function toFormData(data) {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    return formData;
}

export function justTheValue(event) {
    if (!event) {
        return undefined;
    }
    event.preventDefault();
    event.stopPropagation();
    return event.target?.value;
}

export function throttle(fn, millis) {
    let throttled = false;
    return (...args) => {
        if (throttled) {
            return;
        }
        throttled = true;
        fn(...args);
        setTimeout(() => throttled = false, millis);
    }
}
