import React from 'react';
import ReactDOM from 'react-dom';

import { ROUTES } from './constants';
import useMobile from './hooks/usemobile';
import Home from './pages/home';
import GenomeVariation from './pages/genome_variation';
import Login from './pages/login';
import Admin from './pages/admin';
import Kibana from './pages/kibana';
import EditGene from './pages/edit';

import './index.scss';

const IS_NOT_TESTING = process.env.NODE_ENV !== "test";


function isRoute(route) {
    const path = window.location.pathname;
    return path === route || path.startsWith(route + "/");
}

function PageNotFound() {
    return (
        <div className="page-not-found">
            <h1><img src="/logo.png" alt="The HGV Database" /></h1>
            <div className="message">Page not found</div>
        </div>
    );
}

const FIX_ROUTE_MAP = {
    [ROUTES.root]: mobile => <Home mobile={mobile} />,
    [ROUTES.login]: mobile => <Login mobile={mobile} />,
    [ROUTES.admin]: mobile => <Admin mobile={mobile} />,
    [ROUTES.kibana]: mobile => <Kibana mobile={mobile} />,
    [ROUTES.create]: mobile => <EditGene mobile={mobile} create={true} />,
}

const PREFIX_ROUTE_MAP = {
    [ROUTES.editPrefix]: mobile => <EditGene mobile={mobile} />,
    [ROUTES.genomeVariationPrefix]: mobile => <GenomeVariation mobile={mobile} />,
}


export default function App() {
    const mobile = useMobile();

    const handler = FIX_ROUTE_MAP[window.location.pathname] ||
        Object.entries(PREFIX_ROUTE_MAP).find(([prefix]) => isRoute(prefix))?.[1];

    return handler ? handler(mobile) : <PageNotFound />;
}

IS_NOT_TESTING && ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'));
