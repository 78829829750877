import { useEffect } from 'react';
import { API } from '../constants';
import { jsonFetch } from '../lib/fetch';
import useState from './usestate';


// custom hook, see https://reactjs.org/docs/hooks-custom.html
export default function useUser(setError, onUnauthenticated) {
    const [user, setUser] = useState(null, "user");
    const [flashGenes, setFlashGenes] = useState(null, "flashGenes");
    useEffect(() => {
        async function fetchUser() {
            try {
                const json = await jsonFetch.post(API.login, { csrf: false });
                if (json.error) {
                    setError(json.error)
                } else if (json.user) {
                    setUser(json.user);
                    if (json.flash_genes) {
                        setFlashGenes(json.flash_genes);
                    }
                } else if (onUnauthenticated) {
                    onUnauthenticated();
                }
            } catch (e) {
                console.error("caught exception", e);
                setError("Cannot retrieve user: " + e.message);
            }
        }
        fetchUser();
    }, [setError, onUnauthenticated]);
    return [user, flashGenes];
}
