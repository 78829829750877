import React, { useCallback, useEffect } from 'react';
import { API, GOTO, ROUTES, DOI_BASE_URL, MESSAGE_TIMEOUT, MESSAGES } from '../constants';
import { SearchInput } from '../components/search';
import { Dropdown } from '../components/dropdown';
import { DesktopTable, ErrorPane } from '../components/maintable';
import { makeTemporary } from '../lib/utils';
import { jsonFetch } from '../lib/fetch';
import { Sticky } from '../lib/sticky';
import useQuery from '../hooks/usequery';
import useState from '../hooks/usestate';
import useUser from '../hooks/useuser';
import './admin.scss';

const temporary = makeTemporary(MESSAGE_TIMEOUT);

function Admin() {
    const [error, setError] = useState(null, "error");
    const [message, setMessage] = useState(null, "message");
    const [user, flashGenes] = useUser(setError, GOTO.login);
    const query = useQuery(setError, { showPrivateGenes: true });

    useEffect(() => {
        if (flashGenes?.length) {
            temporary(setMessage, MESSAGES.geneSaved(flashGenes[0]));
        }
    }, [flashGenes])

    if (!user) {
        return null;
    }

    return (
        <>
            <h1><img src="/logo.png" alt="The HGV Database" /></h1>

            <div className="admin">
                <div className="user">
                    <div>
                        <span>Admin </span>
                        <span>(</span>
                        <button className="link-like logout" onClick={() => logoutUser(setError)}>Logout</button>
                        <span>)</span>
                    </div>
                    <div>
                        <a className="stats" href={ROUTES.kibana}>Statistics</a>
                    </div>
                </div>

                <div className={"message " + (error ? "error" : message ? "success" : "")}>
                    <span className="icon" />
                    {error || message}
                </div>

                <div>
                    <Sticky minTop={0} stuckClassName="stuckheader">
                        <div className="toolbar">
                            <button className="gray newitem" onClick={GOTO.create}>+ Add new item</button>
                            <SearchInput placeholder="search the database..."
                                value={query.params.searchTerm}
                                onChange={query.setSearchTerm}
                                onSubmit={query.search}
                            />
                        </div>
                    </Sticky>

                    <MainTable error={error} query={query} setMessage={setMessage} setError={setError}
                        flashGenes={flashGenes} />
                </div>
            </div>
        </>
    );
}

function MainTable({ error, query, setMessage, setError, flashGenes }) {
    if (!query.results) {
        if (error) {
            return <ErrorPane error={error} />;
        } else {
            // don't render anything until we have results
            return null;
        }
    }

    const COLUMNS = [
        ['name', "Gene name"],
        ['user', "Added by"],
        ['created', "Date added",
            item => <DateRenderer item={item} setMessage={setMessage} setError={setError} query={query} />],
        ['doi', "DOI", item => <DoiRenderer item={item} />],
        ['actions', "Actions",
            item => <ActionsRenderer item={item} setMessage={setMessage} setError={setError} query={query} />],
    ];

    const results = query.results;
    if (results?.items?.length && flashGenes?.length) {
        for (const gene of flashGenes) {
            const index = results.items.findIndex(item => item.id === gene.id);
            if (index >= 0) {
                results.items[index] = gene;
            } else {
                results.items.unshift(gene);
            }
        }
    }

    return <DesktopTable columns={COLUMNS} results={results} error={error} clear={query.clear}
        nextPage={query.nextPage} sortKeyOrder={query.sortKeyOrder} sortBy={query.sortBy} />;
}

const DATE_OPTIONS = { year: '2-digit', month: '2-digit', day: '2-digit' };
function DateRenderer({ item, setMessage, setError, query }) {
    const millisTimestamp = 1000 * item.created;
    const date = new Date(millisTimestamp).toLocaleDateString("de-DE", DATE_OPTIONS); // dd.mm.yy
    if (item.public) {
        return <span>{date}</span>;
    }
    function publish() {
        publishItem(item, setMessage, setError, query);
    }
    return <>
        <div>{date}</div>
        (<button className="link-like tight publish" onClick={publish}> Publish </button>)
    </>
}

function DoiRenderer({ item }) {
    return <a href={DOI_BASE_URL + item.doi}>{item.doi}</a>;
}

function ActionsRenderer({ item, setMessage, setError, query }) {
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = useCallback(
        (_event, x) => setIsOpen(x !== undefined ? (x?.isVisible ?? x) : (s => !s)),
        [setIsOpen]
    );

    if (item.public) {
        return <button className="link-like edit" onClick={() => GOTO.edit(item.id)}>Edit</button>
    } else {
        function onEdit() {
            GOTO.edit(item.id);
            setIsOpen(false);
        }
        function onPublish() {
            publishItem(item, setMessage, setError, query);
            setIsOpen(false);
        }
        function onDelete() {
            deleteItem(item, setMessage, setError, query);
            setIsOpen(false);
        }
        return <Dropdown isVisible={isOpen} onToggle={onToggle}>
            <button className="edit" onClick={onEdit}>Edit this gene</button>
            <button className="publish" onClick={onPublish}>Publish this gene</button>
            <button className="delete" onClick={onDelete}>Delete this gene</button>
        </Dropdown>;
    }
}

function logoutUser(setError) {
    jsonFetch.post(API.logout)
        .then(json => {
            if (json.success) { GOTO.root() }
        })
        .catch(setError);
}

async function publishItem(item, setMessage, setError, query) {
    try {
        const json = await jsonFetch.put(API.genomeVariation(item.id), { formData: { public: '1' } });
        if (json.error) {
            temporary(setError, "Cannot publish genome variation: " + json.error);
        } else if (json.success) {
            item.public = 1;
            query.updateLocalResultItem(item);
            temporary(setMessage, "1 item published successfully");
        }
    } catch (e) {
        console.error("caught exception", e);
        temporary(setError, "Cannot publish genome variation: " + e);
    }
}

async function deleteItem(item, setMessage, setError, query) {
    try {
        const json = await jsonFetch.delete(API.genomeVariation(item.id));
        if (json.error) {
            temporary(setError, "Cannot delete genome variation: " + json.error);
        } else if (json.success) {
            query.deleteLocalResultItem(item);
            temporary(setMessage, "1 item deleted successfully");
        }
    } catch (e) {
        console.error("caught exception", e);
        temporary(setError, "Cannot delete genome variation: " + e);
    }
}

export default Admin;
