import React, { useCallback } from 'react';
import { default as FeCoreSearchInput } from "@figshare/ui/searchInput";
import { justTheValue } from '../lib/utils';
import style from "./search.css";



export function SearchInput({ placeholder, value, onChange, onSubmit }) {
    const myChange = useCallback(e => onChange(justTheValue(e)), [onChange]);
    const mySubmit = useCallback(e => onSubmit(justTheValue(e)), [onSubmit]);
    return (
        <FeCoreSearchInput
            className={"inputbackground " + style.searchInput}
            size="S"
            placeholder={placeholder}
            value={value}
            onChange={myChange}
            onSubmit={mySubmit}
        />
    );
}
